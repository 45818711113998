<template>
  <v-container>
    <v-dialog v-model="dialog" fullscreen class="ma-0 pa-0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" outlined color="blue" class="py-10 " @click="show" block>
          iniciar
          <v-icon>mdi-play-outline</v-icon>
        </v-btn>
      </template>
      <v-card color="#47A4AD" class="ma-auto pa-0" v-if="mostra_senha">
        <v-container>
          <v-card class="my-5 py-6" color="#0d3c4e" min-height="600">
            <v-card-text class="my-auto">
              <p class="my-6 text-h2 text-center grey--text">{{ senha_atual.tipo }}</p>
              <p class="my-10 text-h1 text-center  white--text font-weight-bold">SENHA {{ senha_atual.ds_senha }}</p>
              <p class="my-10 text-h2 text-center  white--text font-weight-bold" >
                {{ senha_atual.nm_local_estacao }}</p>
              <p class="my-6 text-h4 text-center  white--text" v-if="senha_atual">chamada em {{ senha_atual.dt_chamada }}
              </p>
            </v-card-text>
          </v-card>
        </v-container>
      </v-card>
      <v-card color="#47A4AD" class="ma-0 pa-0" v-else>
        <v-row class="ma-0 pa-0">
          <v-col cols="5">

            <v-card color="grey" min-height="385" class="my-5 py-1">
              <v-card-title class="pa-4"><span class="text-h6 white--text">Senha atual:</span></v-card-title>

              <v-card color="#0d3c4e" class="mx-4">
                <v-card-text>
                  <p class="text-h7 text-center grey--text">{{ senha_atual.tipo }}</p>
                  <p v-if="!senha_atual.nm_paciente" class="text-h1 text-center  white--text font-weight-bold">
                    {{ senha_atual.ds_senha }}</p>
                  <p v-else class="text-h4 text-center  white--text font-weight-bold">
                    {{ senha_atual.nm_paciente }}</p>
                  <p class="text-h7 text-center white--text"> <span class="text-h4 font-weight-bold">
                    {{ senha_atual.nm_local_estacao }}</span></p>
                  <p class="text-h6 text-center  white--text" v-if="senha_atual">chamada em {{ senha_atual.dt_chamada }}
                  </p>
                </v-card-text>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="7" class="my-auto px-2">

            <!-- <video autoplay  src="../../assets/banner-1.jpg"></video> -->
            <v-row class="ma-0 py-auto">
              <v-col cols="12">
                <video width="100%" loop muted autoplay>
                  <source src="../../assets/2.mp4" type="video/mp4">
                  Seu navegador não suporta HTML5.
                </video>
              </v-col>
            </v-row>
            <!--
            <iframe 
              width="100%" 
              height="100%" 
              src="https://www.youtube.com/embed/k7_oQjMts4I?controls=1" 
              title="YouTube video player" 
              frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
            -->
          </v-col>
          <!-- Lista as ultimas maquinas -->
        </v-row>
        <v-row class="mx-2 my-0 px-2 py-0">
          <v-card width="100%" color="grey">
            <v-card-title class="px-4"><span class="text-h4 white--text">Ultimas senhas chamadas:</span></v-card-title>
            <v-row class="px-4">
              <v-col class="mb-2 " v-for="(i, k) in ultimas_senhas" :key="`id-${k}`" cols="4">
                <v-card color="#0d3c4e" class="ma-0 px-0 py-0">
                  <v-card-text>
                    <p class="text-center grey--text font-weight-bold">{{ i.tipo }}</p>
                    <p v-if="!i.nm_paciente" class="display-1 text-center white--text font-weight-bold">
                      {{ i.ds_senha }}<br></p>
                    <p v-else class="display-1 text-center white--text font-weight-bold">
                      {{ i.nm_paciente }}<br>{{ i.nm_local_estacao }}</p>
                    <p class="text-h6 text-center white--text">chamada em {{ i.dh_processo }}</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-row>
        <v-row style="background-color:grey; margin: 0;padding: 0;">
          <v-col 
            v-for="(i,k) in chamadas" :key="`c-${k}`"
            :cols="colunas"
          >
            <v-card
              color="#0d3c4e"     
            >                  
              <v-card-text>
                {{i}}
                <p class="title text-center grey--text">{{i}}</p>
                <p class="title text-center  white--text font-weight-bold">{{i.senha}}<br>{{i.local}}</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import AWS from 'aws-sdk';
import { mapGetters } from 'vuex';
import AppEtlService from '@/service/AppEtlService'
const api = new AppEtlService();
AWS.config.region = 'sa-east-1';
AWS.config.credentials = new AWS.Credentials('AKIAYX3YKDAGJQ2CFKO7', '03X4CV+Skj9TFbjKaYHev6tQ6yQhKmupNbKbid7H');
const polly = new AWS.Polly();

const alertaElemento = new Audio(require('../../assets/aviso1.mp3'));
alertaElemento.setAttribute('crossorigin', 'anonymous');
const speechParams = {
  OutputFormat: "mp3", // For example, 'mp3'
  SampleRate: "16000", // For example, '16000
  Text: "teste de voz", // The 'speakText' function supplies this value
  TextType: "text", // For example, "text"
  VoiceId: "Vitoria" // For example, "Matthew"
};

let url = `ws://10.90.0.7:8001/ws/socket-server/`    
const painelSocket = new WebSocket(url)
export default {
  name: 'Painel',
  components: {
  },
  props: {
    chamadas: {
      type: Array
    },
    totem: {
      type: String
    },
    painel: {
      type: Object
    }

  },
  data: () => ({
    dialog: false,
    inicio: 0,
    tempo:[{tipo:null}],
    local_senha: [],
    ultima_senha_chamada: [],
    utlimas_senhas_locais: [],
    lista_ultimas_senhas: [],
    lista: [],
    texto: [],
    processadas_chamadas: [],
    mostra_senha: false,
    time: 3000,
    contador: null,
  }),
  computed: {
    ...mapGetters(['lista_2', 'lista_5']),
    get_ultimos_locais() {
      return this.lista_5
    },
    get_lista_ultimas_senhas() {
      return this.lista_ultimas_senhas.slice(0, 3)
    },
    colunas() {
      return 12 / this.chamadas.length
    },
    display_text() {
      return this.$vuetify.breakpoint.width > 1200 ? 'display-1 text-center grey--text' : 'title text-center grey--text'
    },
    senha_atual(){
      
      return this.tempo[0]
    },
    ultimas_senhas(){
      return this.tempo.slice(0,3)
    }
    
  },
  methods: {
    /*chama_senha(e){
      this.alerta()
      setTimeout(()=>{
        this.leitor(e)
      },3000)
    },*/
    
    alerta() {
      //alertaElemento.play();
      console.log('alerta')
    },

    leitor() {

      for (let i = 0; i < this.texto.length; i++) {

        //console.log(this.texto[i])
        this.mostra_senha = true
        speechParams.Text = this.texto[i]
        let to1 = setTimeout(() => {
          if (location.pathname !== '/painel') {
            clearTimeout(to1)
          }
          //console.log('time out')
          this.alerta()
        }, 100)
        let to2 = setTimeout(() => {
          if (location.pathname !== '/painel') {
            clearTimeout(to2)
          }
          polly.synthesizeSpeech(speechParams, (err, data) => {
            if (err) {
              console.log(err, err.stack); // an error occurred
              //alert("Error calling Amazon Polly. " + err.message);
            }
            else {
              const uInt8Array = new Uint8Array(data.AudioStream);
              const arrayBuffer = uInt8Array.buffer;
              const blob = new Blob([arrayBuffer]);
              const url = URL.createObjectURL(blob);
              const audioElement = new Audio([url]);
              audioElement.play();
              this.show();
            }
          });
          //  console.log(x)

        }, 500)
        let to3 = setTimeout(() => {
          if (location.pathname !== '/painel') {
            clearTimeout(to3)
          }
          this.mostra_senha = false
          return true
        }, 15000)
      }
      this.texto = []
      return false


    },
    async lista_senha(totem = '', maquina = '') {
      this.loading = true
      return await api
        .get_query('get_ultima_senha_cha', [maquina])
        .then(response => { this.ultima_senha_chamada = response.data.slice(0) })
        .catch(error => { console.log(totem, error) })
        .finally(() => { this.loading = false })

    },
    /*
    async get_utlimas_senhas_locais(totem='',maquina=''){
      
      this.loading = true
      return await api
      .get_query('get_local_senha',[totem, maquina])
      .then( response => { this.utlimas_senhas_locais =  response.data})
      .catch( error => { console.log(error)})
      .finally( ()=>{ this.loading = false})
      
    },*/

    async get_utlimas_senhas_chamadas(maquina = '') {
      this.loading = true
      return await api
        .get_query('get_ultima_senha_maq', [maquina])
        .then(response => { this.lista_ultimas_senhas = response.data })
        .catch(error => { console.log(error) })
        .finally(() => { this.loading = false })
    },
    send(val){
      console.log(JSON.stringify(val))
      painelSocket.send(JSON.stringify(val))
    },
    show() {
      // this.send({ status:'iniciar', ds_maquina: this.painel.totem.text })
      
      // let tmp = []
      // this.mostra_senha = false
      // painelSocket.onmessage = function(e){
      //   console.log(e)
      //   // let data = JSON.parse(e.data);
      //   // data.forEach((v)=>{
      //   //   tmp.push(v)
      //   // })
      // }
      // this.tempo = tmp
      
    },

  },
  beforeDestroy(){
    painelSocket.close()
  },
  mounted() {
    
    polly.synthesizeSpeech(speechParams, (err, data) => {
            data
            if (err) {
              console.log(err, err.stack); // an error occurred
              //alert("Error calling Amazon Polly. " + err.message);
            }
            else {
              const uInt8Array = new Uint8Array('Alô mundo');
              const arrayBuffer = uInt8Array.buffer;
              const blob = new Blob([arrayBuffer]);
              const url = URL.createObjectURL(blob);
              const audioElement = new Audio([url]);
              audioElement.play();
              console.log('SOM')
            }
          });
  },
  watch:{
    mostra_senha:{
      deep: true,
      handler(val){
        console.log(val)
        
          setTimeout(()=>{
            val = false
          },3000)
        
        console.log(val)
      }
    }
  }
}
</script>